import React from 'react'

import RoomPreviews from '../features/roomPreviewsDisplay/RoomPreviews'

const HomePage = () => {
  return (
    <RoomPreviews></RoomPreviews>
  )
}

export default HomePage
